import { useIntl } from "react-intl";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import {
  askNewChatQuestion,
  answerNewChatQuestion,
  generateRelatedQuestions,
  askRegularQuestion,
  answerRegularQuestion,
  generateImage,
  updateNewRegularQuestion,
  updateCredits,
  updateCreditLimit,
  getActivePlan,
  IMessage,
  IChatModel,
  removeRelatedQuestions,
  getNewChatTopic,
  updatedRegularQuestion,
  ISendMessageBody,
  regenerateModelNme,
} from "redux/actions";
import useChat from "./useChat";
import { IUploadFile } from "pages/ChatPage";

interface UseMessageHandlerProps {
  chatId: string | null;
  newMessages: IMessage[];
  messages: IMessage[];
  setSendMessageLoading: Dispatch<SetStateAction<boolean>>;
  setIsGenerating: Dispatch<SetStateAction<boolean>>;
  setOpenHistory: Dispatch<SetStateAction<boolean>>;
  setIGLoading: Dispatch<SetStateAction<boolean>>;
  scrollToBottom: (options: { behavior: "auto" | "smooth" }) => void;
  setUploadingFiles: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link: Dispatch<SetStateAction<string[]>>;
  setIsFileUploading: Dispatch<SetStateAction<boolean>>;
  setIsAnswerComplete: Dispatch<SetStateAction<boolean>>;
  setIsAllChunksReceived: Dispatch<SetStateAction<boolean>>;
  setDone: Dispatch<SetStateAction<boolean | null>>;
  abortControllerRef: React.MutableRefObject<AbortController | null>;
  startStream: (data: ISendMessageBody) => Promise<unknown>;
  searchQuery: string;
}

export const useMessageHandler = ({
  chatId,
  newMessages,
  messages,
  setSendMessageLoading,
  setIsGenerating,
  setOpenHistory,
  setIGLoading,
  scrollToBottom,
  setUploadingFiles,
  setFileS3Link,
  setIsFileUploading,
  setIsAnswerComplete,
  setIsAllChunksReceived,
  setDone,
  abortControllerRef,
  startStream,
  searchQuery,
}: UseMessageHandlerProps) => {

  const { formatMessage } = useIntl();
  const { triggerNotification } = useAppNotification();
  const { getMessageAgainstKey } = useChat();
  const gptModel = useSelector((state) => state.authReducer.gptModel);
  const chatModel = useSelector((state) => state.authReducer.gptModel);
  const { chatSetting, PrivateChat } = useSelector(
    (state) => state.chatReducer
  );
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const imageAttributes = useSelector(
    (state) => state.authReducer.imageAttributes
  );
  const {regenerateAttribute} = useSelector(
    (state) => state.authReducer
  );
  const searchQueryRef = useRef(searchQuery);

  useEffect(() => {
    searchQueryRef.current = searchQuery;
  }, [searchQuery])

  const onNewTextChat = (
    question: string,
    chatModels: IChatModel = {} as IChatModel,
    regenerate?: boolean,
    images?: string[],
    filePath?: string[]
  ) => {
    const cleanedNewMessages = newMessages.filter((message) => {
      return message !== undefined;
    });
    const messageWithQuestions = getMessageAgainstKey({
      messagesArray: cleanedNewMessages,
      key: "related_questions",
    });
    if (messageWithQuestions?.id) {
      removeRelatedQuestions({
        messageId: messageWithQuestions?.id,
        messageViewType: "new",
      });
    }

    const initialImages =
      images &&
      images.map((url, index) => ({
        id: index + 1,
        model_type: "",
        model_id: 1,
        name: null,
        path: url,
        size: "",
        created_at: "",
        updated_at: "",
      }));

    const files =
      filePath &&
      filePath.map((url, index) => ({
        id: index + 1,
        model_id: 1,
        name: "",
        path: url,
        created_at: "",
        updated_at: "",
      }));

    askNewChatQuestion({
      content: question.trim(),
      type: "user",
      images: initialImages ? initialImages : [],
      files: files ?? undefined,
    });

    answerNewChatQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    const messageWithChatId = getMessageAgainstKey({
      messagesArray: newMessages,
      key: "chat_id",
    });

    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    if (newMessages[0]?.images?.length > 0 || (images && images?.length > 0)) {
      localStorage.setItem("GptModel", JSON.stringify(gptModel));
      localStorage.setItem("imageChatGptModel", JSON.stringify(gptModel));
    } else if (
      chatModel?.type?.includes("document") &&
      ((filePath && filePath.length > 0) ||
        (newMessages[0]?.files && newMessages[0]?.files.length > 0))
    ) {
      localStorage.setItem("documentChatGptModel", JSON.stringify(gptModel));
      localStorage.setItem("GptModel", JSON.stringify(gptModel));
    }

    abortControllerRef.current = new AbortController();
    setUploadingFiles([]);
    setFileS3Link([]);

    if (regenerate === true) {
      regenerateModelNme(chatModels && chatModels.name
        ? (chatModels?.name as string)
        : (chatModel?.name as string))
    }

    startStream({
      message: question,
      newChatBoolean: messageWithChatId?.chat_id ? false : true,
      chatId: messageWithChatId?.chat_id || null,
      model:
        chatModels && chatModels.model
          ? (chatModels?.model as string)
          : (chatModel?.model as string),
      chatType:
        chatModel?.type?.includes("document") &&
          ((filePath && filePath.length > 0) ||
            (newMessages[0]?.files && newMessages[0]?.files.length > 0))
          ? "document"
          : newMessages[0]?.images.length > 0 || (images && images?.length > 0)
            ? "image_chat"
            : "text",
      messageViewType: "new",
      images: images,
      file_path: filePath,
      regenerate: regenerate ? regenerate : false,
      isPrivate: PrivateChat,
    })
      .then((res: any) => {
        regenerateModelNme('');
        if (userDetail?.user?.activeSubscription?.name === "Free")
          getActivePlan();
        setIsFileUploading(false);
        setOpenHistory(false);

        if (!res || !res.chatId) return;
        if (chatSetting?.related_questions) {
          generateRelatedQuestions({
            chatId: res.chatId,
            messageViewType: "new",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }
      })
      .catch((error: any) => {
        if (error.message === "Failed to fetch") {
          answerNewChatQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
        if(error.message === "Team has been deleted by admin"){
          answerNewChatQuestion({
            type: "assistant",
            content: error.message,
            images: [],
            isNew: false,
          });
          triggerNotification({ message: formatMessage({ id: "team.account.deleted" }), type: "error" });
        }
        setOpenHistory(false);
        setSendMessageLoading(false);
        setIsAnswerComplete(true);
        setIsAllChunksReceived(false);
        setIsGenerating(false);
        setIsFileUploading(false);
        setDone(true);
        setOpenHistory(false);
      });
  };

  const onRegularTextChat = (
    question: string,
    chatModels: IChatModel = {} as IChatModel,
    regenerate?: boolean | undefined,
    images?: string[],
    filePath?: string[]
  ) => {
    const messageWithQuestions = getMessageAgainstKey({
      messagesArray: messages,
      key: "related_questions",
    });
    if (messageWithQuestions?.id) {
      removeRelatedQuestions({
        messageId: messageWithQuestions?.id as number,
        messageViewType: "history",
      });
    }

    const initialImages =
      images &&
      images.map((url, index) => ({
        id: index + 1,
        model_type: "",
        model_id: 1,
        name: null,
        path: url,
        size: "",
        created_at: "",
        updated_at: "",
      }));

    const files =
      filePath &&
      filePath.map((url, index) => ({
        id: index + 1,
        model_id: 1,
        name: "",
        path: url,
        created_at: "",
        updated_at: "",
      }));

    askRegularQuestion({
      type: "user",
      content: question?.trim(),
      chat_id: Number(chatId),
      images: initialImages ? initialImages : [],
      files: files ?? [],
    });
    answerRegularQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    const messageWithFile = getMessageAgainstKey({
      messagesArray: messages,
      key: "file",
    });
    abortControllerRef.current = new AbortController();
    setUploadingFiles([]);
    setFileS3Link([]);

    if (regenerate === true) {
      regenerateModelNme(chatModels && chatModels.name
        ? (chatModels?.name as string)
        : (chatModel?.name as string))
    }

    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    startStream({
      message: question,
      newChatBoolean: false,
      chatId: Number(chatId),
      model:
        chatModels && chatModels.model
          ? (chatModels?.model as string)
          : (chatModel?.model as string),
      chatType:
        chatModel?.type?.includes("document") &&
          ((messageWithFile?.files && messageWithFile?.files[0]?.path) ||
            (newMessages[0]?.files && newMessages[0]?.files.length > 0) ||
            (messages[0]?.files && messages[0]?.files?.length > 0) ||
            (filePath && filePath?.length > 0))
          ? "document"
          : (messages[0]?.images && messages[0]?.images.length > 0) ||
            (images && images.length > 0)
            ? "image_chat"
            : "text",
      messageViewType: "history",
      file_path: filePath,
      images: images,
      regenerate: regenerate ? regenerate : false,
      isPrivate: PrivateChat,
    })
      .then(() => {
        regenerateModelNme('');
        setIsFileUploading(false);
        if (chatSetting?.related_questions) {
          generateRelatedQuestions({
            chatId: Number(chatId),
            messageViewType: "history",
            signal: abortControllerRef?.current?.signal,
          }).catch((err) => {
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
        }
      })
      .catch((error) => {
        if (error.message === "Failed to fetch") {
          answerRegularQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
        if(error.message === "Team has been deleted by admin"){
          answerRegularQuestion({
            type: "assistant",
            content: error.message,
            images: [],
            isNew: false,
          });
          triggerNotification({ message: formatMessage({ id: "team.account.deleted" }), type: "error" });
        }

        setSendMessageLoading(false);
        setIsAnswerComplete(true);
        setIsAllChunksReceived(false);
        setIsGenerating(false);
        setIsFileUploading(false);
        setDone(true);
        setOpenHistory(false);
      });
  };

  const onNewIGChat = (question: string, regenerate: boolean, chatModels: IChatModel = {} as IChatModel,) => {
    askNewChatQuestion({
      content: question.trim(),
      type: "user",
      images: [],
    });
    answerNewChatQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    const messageWithChatId = getMessageAgainstKey({
      messagesArray: newMessages,
      key: "chat_id",
    });

    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    generateImage({
      message: question.trim(),
      chatId: messageWithChatId?.chat_id ?? null,
      newChatBoolean: messageWithChatId?.chat_id ? false : true,
      model: chatModels?.model ? chatModels.model : chatModel?.model as string,
      chatType: "image",
      imageCount: regenerate? Number(regenerateAttribute?.count) : Number(imageAttributes?.count),
      size: regenerate? regenerateAttribute?.size?.value as string : imageAttributes?.size?.value as string,
      messageViewType: "new",
      isPrivate: PrivateChat,
      // regenerate: regenerate ?? false,
    })
      .then((res: any) => {
        setIGLoading(false);
        answerNewChatQuestion(res?.assistant_message);
        updateNewRegularQuestion(res?.user_message);
        if (userDetail?.user.team.role === "admin") {
          updateCredits(res?.credits);
        } else {
          updateCreditLimit({
            daily_limit: res?.daily_limit,
            used_today: res?.used_today,
          });
        }
        if (!messageWithChatId?.chat_id && res?.chat?.is_private === false && searchQueryRef.current === '') {
          setTimeout(
            () =>
              getNewChatTopic(res?.chat?.id)
                .then(() => {
                  if (searchQueryRef.current === '') {
                  }
                })
                .catch((err: any) => console.error(err)),
            4000
          );
        }
      })
      .catch((error: any) => {
        setIGLoading(false);
        if (error?.data?.message === "Network Error") {
          answerNewChatQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
        if(error.message === "Team has been deleted by admin"){
          // answerNewChatQuestion({
          //   type: "assistant",
          //   content: '',
          //   images: [],
          //   isNew: false,
          // });
          triggerNotification({ message: formatMessage({ id: "team.account.deleted" }) , type: "error" });
        }
      });
  };

  const onRegularIGChat = (question: string, regenerate: boolean, chatModels: IChatModel = {} as IChatModel,) => {
    askRegularQuestion({
      type: "user",
      content: question.trim(),
      chat_id: Number(chatId),
      images: [],
    });
    answerRegularQuestion({
      type: "assistant",
      content: "",
      isNew: true,
      images: [],
    });

    setTimeout(() => scrollToBottom({ behavior: "smooth" }), 10);
    generateImage({
      message: question.trim(),
      chatId: Number(chatId) ?? null,
      newChatBoolean: chatId ? false : true,
      model: chatModels?.model ? chatModels.model : chatModel?.model as string,
      chatType: "image",
      imageCount: regenerate? Number(regenerateAttribute?.count) : Number(imageAttributes?.count),
      size: regenerate? regenerateAttribute?.size?.value as string : imageAttributes?.size?.value as string,
      messageViewType: "history",
      isPrivate: PrivateChat,
      // regenerate: regenerate ?? false,
    })
      .then((res: any) => {
        setIGLoading(false);
        answerRegularQuestion(res?.assistant_message);
        updatedRegularQuestion(res?.user_message);
        if (userDetail?.user.team.role === "admin") {
          updateCredits(res?.credits);
        } else {
          updateCreditLimit({
            daily_limit: res?.daily_limit,
            used_today: res?.used_today,
          });
        }
      })
      .catch((error: any) => {
        setIGLoading(false);
        if(error.message === "Team has been deleted by admin"){
          // answerRegularQuestion({
          //   type: "assistant",
          //   content: '',
          //   images: [],
          //   isNew: false,
          // });
          triggerNotification({ message: formatMessage({ id: "team.account.deleted" }) , type: "error" });
        }
        if (error?.data?.message === "Network Error") {
          answerRegularQuestion({
            type: "assistant",
            content: formatMessage({ id: "network.lost.chat" }),
            images: [],
          });
        }
      });
  };

  return { onNewTextChat, onRegularTextChat, onNewIGChat, onRegularIGChat };
};