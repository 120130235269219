import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./regeneratewithModel.module.scss";
import { ChatType, IChat, IChatModel, IMessage, setRegenerateImageAttribute } from "redux/actions";
import { useSelector } from "redux/hooks";
import { DownIcon, RegenerateIcon } from "../icons";
import { Dropdown, CustomFlowbiteTheme } from "flowbite-react";
import { Tooltip } from "components";
import CustomButton from "components/Button";
import styled from "styled-components";
import { ImageGenerationOptions } from "pages/ChatPage/components/chatFooter/components/ImageGenerationOption";

interface IProps {
    chatItem?: IChat;
    onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
    messageIndex?: number;
    message?: IMessage;
}

const Button = styled(CustomButton) <{ disabled?: boolean }>`
color: #FFF;
font-family: Raleway;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 240% */
border-radius: 5px;
background: linear-gradient(93deg, #4693E8 -41.18%, #6C53FF 100%);
width: 77px;
gap: 10px;
cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
 pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")}; // Explicitly set hover cursor
}
     &:disabled {
      cursor: not-allowed; opacity: 0.5; // Optional: Reduces the button opacity when disabled for visual feedback 
     }
`;

export const RegenerateImage = ({
    onRegenerate,
    messageIndex,
    message,
}: IProps) => {
    const { gptModel, theme,
        regenerateAttribute
    } = useSelector((state) => state.authReducer);
    const { chatModels } = useSelector((state) => state.chatModelsReducer);
    const chatModel = useSelector((state) => state.authReducer.gptModel);

    const [tab, setTab] = useState<"text" | "image">("text");
    const [regenerateModel, setRegenerateModel] = useState<IChatModel>();
    const [hoveredModel, setHoveredModel] = useState<boolean>(false);
    const [openDropDown, setOpenDropDown] = useState<boolean>(false);
    const [modelName, setModelName] = useState<string>('');
    const [selectedModel, setSelectedModal] = useState<IChatModel>({} as IChatModel);
    const [closeDropDown, setCloseDropdown] = useState<boolean>(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownClick = () => {
        setOpenDropDown((prev) => !prev);
        setCloseDropdown(false);
        if (!openDropDown) {
            setSelectedModal({} as IChatModel);
            setRegenerateImageAttribute({ key: "count", value: 1 });
            setRegenerateImageAttribute({ key: "size", value: gptModel?.attributes?.sizes[0] });
            setRegenerateImageAttribute({
                key: "maxCount",
                value: gptModel?.attributes?.max_image_count,
            });
        }
    };

    //   Detect click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event?.target as Node)) {
                setOpenDropDown(false);
                setHoveredModel(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (gptModel?.type?.includes(ChatType.image)) setTab("image");
        else setTab("text");
    }, [gptModel]);

    useEffect(() => {
        let answerModel = chatModels.filter(
            (model) => model.id === message?.model_id
        );
        let regenerateModel = chatModels.filter(
            (model) => model.id === message?.regenerate_model_id
        );
        setRegenerateModel(answerModel[0] ?? regenerateModel[0]);
        setModelName(answerModel[0]?.name ?? regenerateModel[0]?.name);
    }, [message !== null, openDropDown]);

    const customTheme: CustomFlowbiteTheme["dropdown"] = {
        content:
            "modelModalScrollBar rounded-[10px]",
        floating: {
            base: "z-10 w-[190px] rounded-lg shadow-[0px_33px_9px_0px_rgba(237,237,237,0.01),0px_21px_8px_0px_rgba(237,237,237,0.11),0px_12px_7px_0px_rgba(237,237,237,0.38),0px_5px_5px_0px_rgba(237,237,237,0.65),0px_5px_9px_0px_rgba(237,237,237,0.75)] ml-[32px] outline-none border-none dark:shadow-none focus:outline-none dark:bg-[#252526]",
            style: {
                dark: "bg-[#252526] text-dark-light dark:bg-[#252526]",
                light: "bg-white text-light-default",
                auto: "bg-white text-light-default dark:bg-[#252526] dark:text-dark-default",
            },
        },
    };

    let hideTimeout: NodeJS.Timeout | null = null;

    const handleMouseEnter = () => {
        if (hideTimeout) {
            clearTimeout(hideTimeout);
            hideTimeout = null;
        }
        setHoveredModel(true);
    };

    const handleMouseLeave = () => {
        hideTimeout = setTimeout(() => setHoveredModel(false), 100);
    };

    const handleConfirmClick = () => {
        setOpenDropDown(false);
        setCloseDropdown(true);
        setHoveredModel(false);
        onRegenerate!(messageIndex ? messageIndex : 0, selectedModel);
    };

    return (
        <>
            <div ref={dropdownRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <div className={classNames(styles.regenrationContainer, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}>
                    <Tooltip
                        regenrate={true}
                        regenrateTooltip={true}
                        control={
                            <div
                                className={classNames(styles.icon, {
                                    [styles.light]: theme === "light",
                                    [styles.dark]: theme === "dark",
                                    [styles.iconContainer]: true,
                                })}
                                onClick={() => {
                                    setHoveredModel(false);
                                    setTimeout(() => setOpenDropDown(false), 10);
                                    onRegenerate &&
                                        onRegenerate(
                                            messageIndex ? messageIndex : 0,
                                            {} as IChatModel
                                        )
                                }
                                }
                                data-testid="regenerate-answer-btn"
                            >
                                <RegenerateIcon theme={theme} />
                            </div>
                        }
                        placement="top"
                        theme="light"
                    >
                        <div>
                            <FormattedMessage id="answer.tooltip.regenerate" />
                        </div>
                    </Tooltip>
                    <span onClick={handleDropdownClick}>
                        <Tooltip
                            regenrate={true}
                            tooltipAdjustement={true}
                            control={
                                <Dropdown
                                    theme={customTheme}
                                    label=""
                                    dismissOnClick={closeDropDown}
                                    style={{ width: '175px' }}
                                    renderTrigger={() => (
                                        <div
                                            className={classNames(styles.icon, {
                                                [styles.light]: theme === "light",
                                                [styles.dark]: theme === "dark",
                                            })}
                                            style={{ paddingTop: '6px', paddingBottom: '6px' }}
                                        >
                                            <img
                                                data-testid="regeneration-model-filter"
                                                style={{
                                                    height: ((
                                                        regenerateModel?.image_url ??
                                                        chatModel?.image_url ??
                                                        ""
                                                    ).includes("flux.png") || (
                                                        regenerateModel?.image_url ??
                                                        chatModel?.image_url ??
                                                        ""
                                                    ).includes("stable-diffusion.svg")) ? "13px" : "8.8px"
                                                }}
                                                className={(
                                                    regenerateModel?.image_url ??
                                                    chatModel?.image_url ??
                                                    ""
                                                ).includes("flux.png") ? styles.imagebackground : ""}
                                                src={
                                                    regenerateModel?.image_url ??
                                                    chatModel?.image_url ??
                                                    "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"
                                                }
                                            />
                                            {(hoveredModel || openDropDown) && (
                                                <div
                                                    className={classNames(styles.modalName, {
                                                        [styles.light]: theme === "light",
                                                        [styles.dark]: theme === "dark",
                                                    })}
                                                >
                                                    {modelName ?? chatModel?.name ?? 'GPT-4o mini'}
                                                </div>
                                            )}
                                            <DownIcon theme={theme} />
                                        </div>
                                    )}
                                >
                                    <div onMouseOver={(event: any) => {
                                        event.stopPropagation();
                                    }}>
                                        <>
                                            <div
                                                className={classNames("m-1", styles.DropDownbody, {
                                                    [styles.light]: theme === "light",
                                                    [styles.dark]: theme === "dark",
                                                })}
                                            >
                                                {chatModels.length > 0 ? (
                                                    chatModels
                                                        .filter((model) => model?.type?.includes(tab))
                                                        .map((model, index) => (
                                                            <>
                                                                <div onClick={(e) => e.stopPropagation()}>
                                                                    <Dropdown.Item key={index} className={`${classNames(styles.container, {
                                                                        [styles.light]: theme === "light",
                                                                        [styles.dark]: theme === "dark",
                                                                        [styles.containerHover]: true,
                                                                    })} py-1 transition-none`}
                                                                        data-testid='select-modal'
                                                                    >
                                                                        <div className={`${styles.imagedropdownContainer} ${model.name === "flux-schnell" ? styles.image : ""}`}>
                                                                            <img src={model.image_url} />
                                                                        </div>
                                                                        <div className={styles.textContainer}>
                                                                            <div className={classNames(styles.dropdownModalName, {
                                                                                [styles.dark]: theme === "dark",
                                                                            })}>
                                                                                {model.name}
                                                                            </div>
                                                                            <label className={`${theme === 'dark' ? styles.checkboxDarkContainer : styles.checkboxContainer}`}>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={selectedModel?.model === model?.model ? true : false}
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                    onChange={() => {
                                                                                        if (selectedModel?.model === model?.model) {
                                                                                            setSelectedModal({} as IChatModel)
                                                                                        }
                                                                                        else {
                                                                                            setSelectedModal(model);
                                                                                            if (!model?.attributes?.sizes.some((size) => size.value === regenerateAttribute?.size?.value))
                                                                                                setRegenerateImageAttribute({ key: "size", value: model?.attributes?.sizes[0] });

                                                                                            if ((model?.attributes?.max_image_count !== undefined &&
                                                                                                regenerateAttribute?.count !== undefined &&
                                                                                                regenerateAttribute.count > model.attributes.max_image_count)) {
                                                                                                setRegenerateImageAttribute({ key: "count", value: 1 });
                                                                                                setRegenerateImageAttribute({
                                                                                                    key: "maxCount",
                                                                                                    value: model?.attributes?.max_image_count,
                                                                                                });
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                />
                                                                                <span className={styles.checkmark}></span>
                                                                            </label>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </div>
                                                            </>
                                                        ))
                                                ) : (
                                                    <div className={styles.message}>
                                                        <FormattedMessage id="gptmodel.no.available" />
                                                    </div>
                                                )}
                                            </div>
                                            <div onClick={(e) => e.stopPropagation()}
                                            >
                                                <ImageGenerationOptions selectedModel={selectedModel} regenerateImage={true} />
                                            </div>
                                            <div className="flex justify-center items-center pt-[10px] pb-[14px]" onClick={(e) => e.stopPropagation()}>
                                                <Button
                                                    type='button'
                                                    variant="latest-primary"
                                                    data-testid="gpt-confirm-model"
                                                    onClick={() => {
                                                        if (!_.isEmpty(selectedModel))
                                                            handleConfirmClick()
                                                    }}
                                                    style={{ cursor: _.isEmpty(selectedModel) ? 'not-allowed' : '' }}
                                                >
                                                    <FormattedMessage id="gptModal.btn.confirm" />
                                                </Button>
                                            </div>
                                        </>
                                    </div>
                                </Dropdown>
                            }
                            placement="top"
                            theme="light"
                        >
                            <div>
                                <FormattedMessage id="answer.tooltip.regenerate.other.model" />
                            </div>
                        </Tooltip>
                    </span>
                </div>
            </div>

        </>
    );
};