export interface IAction {
  readonly type: string;
}

export enum TYPES {
  // AUTHENTICATION
  SIGN_IN = "SIGN_IN",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  SOCIAL_CALLBACK = "SOCIAL_CALLBACK",
  LOGOUT = "LOGOUT",
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
  INIT_USER_DETAILS = "INIT_USER_DETAILS",
  UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS",

  // CHAT MODELS
  GET_CHAT_MODELS = "GET_CHAT_MODELS",
  SET_CHAT_MODEL = "SET_CHAT_MODEL",
  SET_IMAGE_ATTRIBUTE = "SET_IMAGE_ATTRIBUTE",
  SET_SHOW_UPSCALE_MODAL = "SET_SHOW_UPSCALE_MODAL",
  SET_DROP_DOWN_OPTIONS = "SET_DROP_DOWN_OPTIONS",
  SET_SELECTED_OPTIONS= "SET_SELECTED_OPTIONS",
  UPDATE_DROP_DOWN_OPTIONS= "UPDATE_DROP_DOWN_OPTIONS",
  SET_REGENERATE_IMAGE_ATTRIBUTE="SET_REGENERATE_IMAGE_ATTRIBUTE",

  // CHAT
  START_NEW_CHAT = "START_NEW_CHAT",
  NEW_CHAT = "NEW_CHAT",
  ANSWER_NEW_CHAT = "ANSWER_NEW_CHAT",
  REGULAR_CHAT = "REGULAR_CHAT",
  ANSWER_REGULAR_CHAT = "ANSWER_REGULAR_CHAT",
  GET_ALL_CHATS = "GET_ALL_CHATS",
  ADD_CHAT_HISTORY = "ADD_CHAT_HISTORY",
  UPDATE_CHAT_HISTORY = "UPDATE_CHAT_HISTORY",
  DELETE_CHAT_HISTORY = "DELETE_CHAT_HISTORY",
  GET_CHAT_HISTORY = "GET_CHAT_HISTORY",
  SAVE_FILE_PATH = "SAVE_FILE_PATH",
  GET_GENERATED_QUESTIONS_NEW = "GET_GENERATED_QUESTIONS_NEW",
  GET_GENERATED_QUESTIONS_HISTORY = "GET_GENERATED_QUESTIONS_HISTORY",
  REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES = "REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES",
  REMOVE_RELATED_QUESTIONS_FROM_MESSAGES = "REMOVE_RELATED_QUESTIONS_FROM_MESSAGES",
  REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES = "REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES",
  REMOVE_LAST_MESSAGE_FROM_MESSAGES = "REMOVE_LAST_MESSAGE_FROM_MESSAGES",
  GET_SHARE_CHAT = "GET_SHARE_CHAT",
  UPDATED_NEW_REGULAR_CHAT="UPDATED_NEW_REGULAR_CHAT",
  UPDATE_REGULAR_CHAT="UPDATE_REGULAR_CHAT",
  GET_CHAT_SETTING="GET_CHAT_SETTING",
  DELETE_MULTIPLE_CHAT_HISTORY="DELETE_MULTIPLE_CHAT_HISTORY",
  CLEAR_NEW_MESSAGES='CLEAR_NEW_MESSAGES',
  GET_ROLL_BOT_SETTING="GET_ROLL_BOT_SETTING",
  GET_ROLL_BOT="GET_ROLL_BOT",
  SET_REMEMBER_Chat_Type= "SET_REMEMBER_Chat_Type",
  SET_CONTAINER_HEIGHT="SET_CONTAINER_HEIGHT",
  SET_DRAG_FILE= "SET_DRAG_FILE",
  SAVE_ALL_CHATS= "SAVE_ALL_CHATS",
  DELETE_ALL_CHATS="DELETE_ALL_CHATS",
  SET_PAGINATION="SET_PAGINATION",
  SET_VIEW_CHAT_HISTORY="SET_VIEW_CHAT_HISTORY",
  SET_SEARCH_WORD="SET_SEARCH_WORD",
  SET_SEARCH_Query="SET_SEARCH_Query",
  SET_Selected_MessageId="SET_Selected_MessageId",
  SET_Scroll_Id="SET_Scroll_Id",
  AUDIO_SRC="AUDIO_SRC",
  //New
  GET_ALL_CHATS_HISTORY = "GET_ALL_CHATS_HISTORY",
  UPDATE_CHAT_MESSAGES="UPDATE_CHAT_MESSAGES",
  DELETE_SELECTED_CHATS="DELETE_SELECTED_CHATS",
  ADD_MESSAGE_CAT_HISTORY="ADD_MESSAGE_CAT_HISTORY",
  ADD_MESSAGE="ADD_MESSAGE",
  UPDATE_MESSAGE='UPDATE_MESSAGE',
  UPDATE_ANSWER="UPDATE_ANSWER",
  NEW_CHAT_QUESTION="NEW_CHAT_QUESTION",
  REMOVE_CHAT_QUESTION="REMOVE_CHAT_QUESTION",
  UPDATE_CHAT_QUESTION="UPDATE_CHAT_QUESTION",
  ADD_MESSAGE_HISTORY="ADD_MESSAGE_HISTORY",
  UPDATE_ASSISTANT_ANSWER="UPDATE_ASSISTANT_ANSWER",
  SET_CHAT_HISTORY_LOADING='SET_CHAT_HISTORY_LOADING',

  //MESSAGE
  MESSAGES_PAGINATION="MESSAGES_PAGINATION",
  LOAD_MESSAGES="LOAD_MESSAGES",
  UPDATE_MESSAGES_PAGINATION="UPDATE_MESSAGES_PAGINATION",
  CLEAR_HISTORY_MESSAGES= "CLEAR_HISTORY_MESSAGES",
  SET_ADMIN_DELETED="SET_ADMIN_DELETED",

  //REGENERATE MODEL NAME
  REGENERATE_MODEL_NAME="REGENERATE_MODEL_NAME",

  // IMAGE GENERATION
  GENERATE_IMAGE_ERROR = "GENERATE_IMAGE_ERROR",
  UPSCALE_IMAGE = "UPSCALE_IMAGE",

  // PLAN SUBSCRIPTION
  GET_PLAN = "GET_PLAN",
  GET_ACTIVE_PLAN = "GET_ACTIVE_PLAN",
  GET_CREDITS_LIST = "GET_CREDITS_LIST",
  UPDATE_CREDITS = "UPDATE_CREDITS",
  GET_CREDIT_LIMITS = "GET_CREDIT_LIMITS",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",

  // TEAM
  GET_ALL_TEAMS = "GET_ALL_TEAMS",
  SELECT_TEAM = "SELECT_TEAM",
  GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS",
  GET_TEAM_INFO = "GET_TEAM_INFO",
  INVITE_TEAM_MEMBER = "INVITE_TEAM_MEMBER",
  REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER",
  UPDATE_MEMBER_ROLE = "UPDATE_MEMBER_ROLE",
  UPDATE_MEMBER_DAILY_LIMIT = "UPDATE_MEMBER_DAILY_LIMIT",
  RESEND_INVITE = "RESEND_INVITE",
  CANCEL_INVITE = "CANCEL_INVITE",

  // SYSTEM
  SET_SYSTEM_THEME = "SET_SYSTEM_THEME",
  GET_SYSTEM_THEME = "GET_SYSTEM_THEME",
  SET_PRIVATE_CHAT="SET_PRIVATE_CHAT",
  SET_REMEMBER_SETTING= "SET_REMEMBER_SETTING",


  // PROPMT LIBRARY
  GET_ALL_PROMPT = "GET_ALL_PROMPT",
  GET_USER_PROMPT="GET_USER_PROMPT",
  DELETE_USER_PROMPT='DELETE_USER_PROMPT',
  UPDATE_USER_PROMPT='UPDATE_USER_PROMPT'
}
