import { Tooltip } from "components";
import { FormattedMessage } from "react-intl";

import {
  // DocIcon,
  //   CopyIcon,
  ShareIcon,
  // RegenerateIcon,
} from "../icons";

import { IChat, IChatModel, IMessage, IShareChatMessage } from "redux/actions";

import classNames from "classnames";
import { useSelector } from "redux/hooks";
import styles from "../messageContainer/answerContainer.module.scss";
// import { RegenerateWithModel } from "../regeneratewithModel";
import { RegenerateImage } from "../regeneratewithModel/regenerateImage";
// import { RegenerateWithModel } from "../regeneratewithModel";

interface IProps {
  share?: boolean;
  shareChat?: boolean;
  shareMessage?: IShareChatMessage;
  messageIndex?: number;
  message?: IMessage;
  toggleShareChat?: () => void;
  handleCheckboxChange?: (messageId: any) => void;
  onRegenerate?: (messageIndex: number, chatModal: IChatModel) => void;
  chatItem?: IChat;
}

export const AssistantMessageAction = ({
  messageIndex,
  message,
  share,
  onRegenerate,
  toggleShareChat,
  handleCheckboxChange,
  chatItem,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { PrivateChat } = useSelector((state) => state.chatReducer);

  return (
    <div className={styles.actionContainer}>
      {/* REGENERATE BUTTON */}
      <div className="flex flex-row items-center mt-1">
        {/* <div className='bg-grayF6F6F6 dark:bg-black252526 inline-block min-w-[24px] h-[24px] rounded-[12px] '>
          <Tooltip
            regenrate={true}
            tooltipAdjustement={true}
            AdjustregenrateTooltip={true}
            control={
              <div
                className={classNames(styles.icon, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                  [styles.iconContainer]: true,
                  [styles.copyContainer]:true,
                })}
                onClick={() =>
                  onRegenerate &&
                  onRegenerate(
                    messageIndex ? messageIndex : 0,
                    {} as IChatModel
                  )
                }
                data-testid="regenerate-answer-btn"
              >

                <RegenerateIcon theme={theme} />
              </div>
            }
            placement="top"
            theme="light"
          >
            <div>
              <FormattedMessage id="answer.tooltip.regenerate" />
            </div>
          </Tooltip>
        </div> */}
        {(!share && PrivateChat === false) && (
          <>
            {/* <RegenerateWithModel
              message={message}
              chatItem={chatItem}
              messageIndex={messageIndex}
              onRegenerate={onRegenerate}
            /> */}
            <Tooltip
              regenrate={true}
              tooltipAdjustement={true}
              control={
                <div
                  className={classNames(styles.icon, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                    [styles.iconContainer]: true
                  })}
                  onClick={() => {
                    toggleShareChat!();
                    handleCheckboxChange!(message?.id);
                  }}
                  data-testid="share-chat-btn"
                >
                  <ShareIcon theme={theme} />
                </div>
              }
              placement="top"
              theme="light"
            >
              <div>
                <FormattedMessage id="share.tooltip.chat" />
              </div>
            </Tooltip>
          </>
        )}

        <RegenerateImage
          message={message}
          chatItem={chatItem}
          messageIndex={messageIndex}
          onRegenerate={onRegenerate}
        />
      </div>
    </div>
  );
};
